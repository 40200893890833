
*:focus {
    outline: none;
}
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:focus {outline: 0;}

p, span, a, button, div {
    font-family: 'promptsemibold';
}
abbr {
font-family: 'promptregular';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  padding: 20px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.select-section {
  position: relative;
  select {
    height: 46px;
  }
  &:before {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: .3rem solid transparent;
    border-right: .3rem solid transparent;
    border-top: .3rem solid #38373A;
    z-index: 10;
  }
}

body,
html {
  background-color: #fcf5f6;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

.spinner-border {
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right-color: transparent;
}

.visually-hidden {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

.Modal {
    padding: .5rem 1rem .75rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    width: 90%;
    max-height: fit-content;
    padding: 24px 24px 36px;
    border-radius: 12px;
    text-align: center;
}

.Overlay {
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
}
